import styled from 'styled-components';

export const ErrorHeroWrapper = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  height: 100vh;
  text-align: center;
`;

export const ErrorHeroMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    max-width: 8em;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    max-width: 27em;
    margin-left: auto;
    margin-right: auto;
  }

  button {
    cursor: pointer;
  }
`;
