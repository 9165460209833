import React from 'react';

const IconArrowheadLeft = ({
  width = 31,
  height = 8,
  fill = 'currentColor',
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 31 8"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M.646 3.646a.5.5 0 000 .708l3.182 3.182a.5.5 0 10.708-.708L1.707 4l2.829-2.828a.5.5 0 00-.708-.708L.646 3.646zM31 3.5H1v1h30v-1z"
      />
    </svg>
  );
};

export default IconArrowheadLeft;
