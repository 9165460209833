import React from 'react';
import ScrollSection from '../components/ScrollSection';

import SEO from '../components/SEO';
import ErrorHero from '../components/404Hero';

const NotFoundPage = () => {
  return (
    <>
      <SEO title="404: Not found" />
      <ScrollSection>
        <ErrorHero />
      </ScrollSection>
    </>
  );
};

export default NotFoundPage;
