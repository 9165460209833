import React from 'react';
import PropTypes from 'prop-types';
import { Heading1 } from '../TextStyles';
import Spacer from '../Spacer';
import Button from '../Button';
import Container from '../_layouts/Container';
import IconArrowheadLeft from '../_svgs/IconArrowheadLeft';

import SplitText from '../_animation/AnimateSplitText';
import AnimateFadeIn from '../_animation/AnimateFadeIn';

import { ErrorHeroWrapper, ErrorHeroMain } from './index.style';
import PageTransitionLink from '../PageTransitionLink';
import Particles from '../Particles';

const ErrorHero = () => {
  return (
    <ErrorHeroWrapper>
      <Particles />
      <Container>
        <ErrorHeroMain>
          <Heading1 as="h1" style={{ position: 'relative' }}>
            <SplitText type={`lines`} heading={true} delay={0.4}>
              Page Not Found
            </SplitText>
          </Heading1>
          <Spacer size={[18, 30]} />
          <AnimateFadeIn delay={0.8}>
            <PageTransitionLink to={`/`}>
              <Button iconLeft={<IconArrowheadLeft />}>Back to Home</Button>
            </PageTransitionLink>
          </AnimateFadeIn>
        </ErrorHeroMain>
      </Container>
    </ErrorHeroWrapper>
  );
};

ErrorHero.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default ErrorHero;
